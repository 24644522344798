// Dependencies
import { ValidationError, ValidationErrorItem } from 'joi'

// Interface
interface Translations {
	[key: string]: string
	base: string
	email: string
	empty: string
	max: string
	min: string
	required: string
}

// Class Export
export class JoiManager {
	private static JoiReasonTranslations: Translations = {
		base: 'debe ser especificado',
		email: 'debe contener un formato valido',
		empty: 'no puede estar vacio',
		max: 'no puede superar los %s elemento(s) o caracter(es) de largo',
		min: 'debe contener al menos %s elemento(s) o caracter(es) de largo',
		required: 'es requerido'
	}

	private static _parseMessage(details: ValidationErrorItem) {
		const { label, limit } = details.context
		const reason = details.type.replace(/^\w+\./, '')
		let translated = JoiManager.JoiReasonTranslations[reason]
		if (reason === 'max' || reason === 'min') translated = translated.replace('%s', limit)
		return `El Campo '${ label }' ${ translated }.`
	}

	static showToastOnError(handler: Function, title: string, error: ValidationError) {
		const details = error.details[0]
		handler(title, JoiManager._parseMessage(details), 'warning')
	}
}