// Classes
import { DevelopmentTools } from '@/Classes/Static/DevelopmentTools'

// Dependencies
import VueMixins from 'vue-typed-mixins'

// Mixins
import MixinComponent  from '@/Mixins/MixinComponent'
import MixinResponsive from '@/Mixins/MixinResponsive'

// Component Extend
const MenuBar = VueMixins(MixinComponent, MixinResponsive).extend({
	name: 'MenuBar',

	props: {
		show: Boolean
	},

	data: function() {
		return {
			items: [],
			states: {
				canClickItem: true,
				isMobile: false,
				selected: undefined
			}
		}
	},

	methods: {
		_getSelectedClass: function(item: any) {
			if (Array.isArray(item.items)) {
				for (const sItem of item.items)
				if (sItem.key === this.states.selected)
				return { ['category-selected']: true }
			}
			return { selected: item.key === this.states.selected }
		},

		getItemByKey: function(key: string) {
			return this.items.find((x: any) => x.key === key)
		},

		setItems: function(items: Array<any>) {
			this.items = items
		},

		onMBItemClick: function(item: any) {
			// Si 'canClickItem' es 'false', prevenir ejecución del evento.
			if (!this.states.canClickItem) return

			// Ejecución normal del evento 'onMBItemClick'.
			DevelopmentTools.printWarn('[MenuBar]:onMBItemClick() event triggered')
			if (Array.isArray(item.items)) {
				// Ocultar la misma categoria si esta abierta.
				if (item.categoryOpened) return item.categoryOpened = false

				// Ocultar todas las categorias.
				for (const _item of this.items) if ('categoryOpened' in _item) _item.categoryOpened = false

				// Mostrar la categoria seleccionada.
				item.categoryOpened = true
			}
			else {
				// Cerrar cualquier opción anterior que se encuentre abierta.
				for (const _item of this.items) if ('categoryOpened' in _item) _item.categoryOpened = false

				if (this.states.selected === item.key) return
				this.setStates<MenuBarRef['states']>({ selected: item.key })
				this.$emit('onMBItemClick', item.key)
			}
		},

		onMBSubItemClick: function(event: MouseEvent, item: any, sItem: any) {
			DevelopmentTools.printWarn('[MenuBar]:onMBSubItemClick() event triggered')
			event.stopPropagation()
			item.categoryOpened = false
			this.setStates<MenuBarRef['states']>({ selected: sItem.key })
			this.$emit('onMBItemClick', sItem.key)
		}
	}
})

// Exports
export default MenuBar
export type MenuBarRef = InstanceType<typeof MenuBar>