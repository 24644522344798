// Classes
import { AlgorithmTools }   from '@/Classes/Static/AlgorithmTools'
import { DevelopmentTools } from '@/Classes/Static/DevelopmentTools'
import { VuexTools }        from '@/Classes/Static/VuexTools'

// Constants
import { AppValues } from '@/Constants/Global/AppValues'
import { Server }    from '@/Constants/Global/Server'
import { VueRouter } from '@/Constants/Global/VueRouter'
import { Vuex }      from '@/Constants/Global/Vuex'

// Dependencies
import VueMixins from 'vue-typed-mixins'

// Mixins
import MixinComponent  from '@/Mixins/MixinComponent'
import MixinFetch      from '@/Mixins/MixinFetch'
import MixinResponsive from '@/Mixins/MixinResponsive'

// Store
import Store from '@/Store/Global/Default'

// Component Extend
const HorizontalStorageSelector = VueMixins(MixinComponent, MixinFetch, MixinResponsive).extend({
	name: 'HorizontalStorageSelector',

	props: {
		defaultItem: String,
		show: Boolean
	},

	data: function() {
		return {
			states: {
				isMobile: false,
				selected: undefined,
				storages: []
			}
		}
	},

	created: function() {
		this._resolveDefaultItemFromProp()
	},

	mounted: function() {
		this._fetchUserStorages()
	},

	computed: {
		_getStorages: function(): any {
			const { storages } = this.states
			return storages
		}
	},

	methods: {
		_fetchUserStorages: async function() {
			// Realizar la Petición al servidor.
			const { _idUser } = Store.getters.getStoredUser
			const response = await this.doFetch({ action: Server.Fetching.Method.GET, path: Server.Routes.Zones.GetStoragesByUser, params: { _idUser } })

			// Si se obtiene una respuesta satisfactoria, continuar con el proceso.
			if (response.status === Server.Response.StatusCodes.SUCCESS) {
				// Almacenar la Información requerida para el Modulo de CheckList.
				const storages = (response.data.body || []) as Array<any>

				// Asignar la Ubicación por Defecto si existe al menos una.
				if (storages?.length > 0) {
					const sorted = storages.sort((a, b) => a.storageCode.toUpperCase() > b.storageCode.toUpperCase() ? 1 : -1)
					this.setStates<HorizontalStorageSelectorRef['states']>({ selected: sorted[0], storages: sorted })
					this._refreshItemsAndUpdateSelection(sorted[0])
                    this.$emit('onHSSItemClick', sorted[0])
				}
			}
		},

		_getSelectedItemClass: function(item: any) {
			return { selected: this.states.selected?.storageCode === item.storageCode }
		},

		_refreshItemsAndUpdateSelection: function(item: any) {
			this.setStates<HorizontalStorageSelectorRef['states']>({ selected: item })
		},

		_resolveDefaultItemFromProp: function() {
			const index = parseInt(this.$props.defaultItem)
			if (!isNaN(index)) {
				const item = this.states.storages[index]
				this.setStates<HorizontalStorageSelectorRef['states']>({ selected: item })
			}
		},

		onHSSItemClick: function(item: any, index: number) {
			DevelopmentTools.printWarn('[HorizontalStorageSelector]:onHSSItemClick() event triggered', index)
			this._refreshItemsAndUpdateSelection(item)
			this.$emit('onHSSItemClick', item)
		}
	}
})

// Exports
export default HorizontalStorageSelector
export type HorizontalStorageSelectorRef = InstanceType<typeof HorizontalStorageSelector>