// Constants
import { ErrorCodes } from '@/Constants/Global/ErrorCodes'
import { Server }     from '@/Constants/Global/Server'

// Class Export
export class ErrorHandler {
	public static parse(error: any, statusCode: number) {
		const { payload } = error.response.data.body

		// Realizar un Analisis del Error según el 'statusCode'.
		switch (statusCode) {
			case Server.Response.StatusCodes.CONFLICT: {
				const message = payload.message as string
				const matched = message.match(ErrorCodes.MongoDB.Codes.DUPLICATE_KEY_ERROR)

				// Si el error se encuentra en el mensaje, obtener la 'key' con el conflicto.
				if (matched) {
					const conflictKey = message.match(/(\{.+\})$/gi)
					const errorInfo = { key: null as string, value: null as string }

					// Finalmente, formatear la información del error.
					if (conflictKey) {
						const parsedInfo = conflictKey[0].replace('{', '').replace('}', '').trim().replace(': ', ':').split(':')
						errorInfo.key   = parsedInfo[0]
						errorInfo.value = parsedInfo[1].replace(/"/g, '')
						return errorInfo
					}
				}

				break
			}
		}
	}
}