// ./
import { parseEquipmentsResponse, parseStackedEquipmentsResponse } from './response.parser'
import { parseZonesResponse, parseStackedZonesResponse } from './response.parser'

// Classes
import { JoiManager }       from '@/Classes/Network/JoiManager'
import { ErrorHandler }     from '@/Classes/Responses/ErrorHandler'

// Components
import SubmitButton              from '@/Components/Extras/SubmitButton/template.vue'
import BasicHeader               from '@/Components/Global/BasicHeader/template.vue'
import DataTable                 from '@/Components/Global/DataTable/template.vue'
import HorizontalStorageSelector from '@/Components/Modules/1/HorizontalStorageSelector/template.vue'
import InternalStorageForm       from '@/Components/Modules/1/InternalStorageForm/template.vue'
import MenuBar                   from '@/Components/Global/MenuBar/template.vue'
import SidePanel                 from '@/Components/Global/SidePanel/template.vue'


// Components (Refs)
import { BasicHeaderRef }               from '@/Components/Global/BasicHeader/component'
import { DataTableRef }                 from '@/Components/Global/DataTable/component'
import { HorizontalStorageSelectorRef } from '@/Components/Modules/1/HorizontalStorageSelector/component'
import { InternalStorageFormRef }       from '@/Components/Modules/1/InternalStorageForm/component'
import { MenuBarRef }                   from '@/Components/Global/MenuBar/component'
import { SidePanelRef }                 from '@/Components/Global/SidePanel/component'


// Constants
import { Breakpoints } from '@/Constants/Global/Breakpoints'
import { Component }   from '@/Constants/Global/Component'
import { Server }      from '@/Constants/Global/Server'
import { VueRouter }   from '@/Constants/Global/VueRouter'
import { Module1 }     from '@/Constants/Modules/Module1'

// Dependencies
import VueMixins from 'vue-typed-mixins'

// Mixins
import MixinBase       from '@/Mixins/MixinBase'
import MixinComponent  from '@/Mixins/MixinComponent'
import MixinFetch      from '@/Mixins/MixinFetch'
import MixinResponsive from '@/Mixins/MixinResponsive'

// Store
import Store from '@/Store/Global/Default'

// Component Extend
const View13 = VueMixins(MixinBase, MixinComponent, MixinFetch, MixinResponsive).extend({
	name: VueRouter.Modules.View13.NAME,

	components: {
		BasicHeader,
		DataTable,
		HorizontalStorageSelector,
		InternalStorageForm,
		MenuBar,
		SidePanel,
		SubmitButton
	},

	data: function() {
		return {
			states: {
				action: Component.Actions.INSERT,
				dataTableParser: null,
				documentToUpdate: null,
				inputButtonKey: undefined,
				isFetching: false,
				showConfirmDialog: false,
				showDataTable: true,
				showHorizontalStorageSelector: false,
				showInternalStorageForm: false,
				variantInternalStorageForm: 'InternalStorage'
			}
		}
	},

	mounted: function() {
		this._initComponents()
		this._initPermissions()
		this._sidePanel.setItems(Module1.M11.SidePanelItems)
		this._dataTable.setStates<DataTableRef['states']>({ isLocalSearch: false, preventDefaultStacked: true })

		// Establecer los elementos del componente 'MenuBar'.
		this._menuBar.setStates<MenuBarRef['states']>({ selected: Module1.M13.Defaults.MenuBarKeyOptions.INTERNAL_STORAGE })
	},

	computed: {
		_basicHeader: function(): BasicHeaderRef {
			return this.$refs.basicHeader as BasicHeaderRef
		},

		_currentActiveMenuBarKey: function(): number {
			const selectedKey = this._menuBar.states.selected as number
			return selectedKey || Module1.M13.Defaults.MenuBarKeyOptions.INTERNAL_STORAGE
		},

		_dataTable: function(): DataTableRef {
			return this.$refs.dataTable as DataTableRef
		},

		_horizontalStorageSelector: function(): HorizontalStorageSelectorRef {
			return this.$refs.horizontalStorageSelector as HorizontalStorageSelectorRef
		},

		_internalStorageForm: function(): InternalStorageFormRef {
			return this.$refs.internalStorageForm as InternalStorageFormRef
		},

		_menuBar: function(): MenuBarRef {
			return this.$refs.menuBar as MenuBarRef
		},

		_sidePanel: function(): SidePanelRef {
			return this.$refs.sidePanel as SidePanelRef
		}
	},

	methods: {
		_deleteInternalStorage: async function() {
			// Datos requeridos para la petición.
			const _idZone    = this.states.documentToUpdate?._idZone
			const name       = this.states.documentToUpdate?.zoneName
			const equipments = [] as Array<any>

			// Objeto con las Propiedades requeridas por la Petición.
			const body = {
				_idZone, name, equipments, isValid: false
			}

			// Validación de los campos de la petición.
			const result = Module1.M13.JoiSchemas.UpdateInternalStorage.validate(body)
			if (result.error) return JoiManager.showToastOnError(this.showToast, 'Error al Actualizar la Ubicación Interna', result.error)

			// Realizar la Petición al servidor.
			this.setStates<View13Ref['states']>({ isFetching: true })
			const response = await this.doFetch({ action: Server.Fetching.Method.PATCH, path: Server.Routes.Zones.UpdateZone, body })

			// Si se obtiene una respuesta satisfactoria, continuar con el proceso.
			if (response.status === Server.Response.StatusCodes.SUCCESS) {
				// Actualizar los Registros de la Tabla.
				this._updateDataTableParser()
				await this._initDataTable(this._currentActiveMenuBarKey, 1, true)
				
				// Mostrar Mensaje.
				this.showToast(`Ubicación Interna eliminada correctamente!`, `La Ubicación Interna ha sido eliminada correctamente. Todos los Equipos de la Ubicación Interna han sido desasignados.`, 'success')
				this._hideConfirmDialog()
			}
		},

		_hideConfirmDialog: function() {
			this.setStates<View13Ref['states']>({ documentToUpdate: null, isFetching: false, showConfirmDialog: false })
		},

		_initComponents: function() {
			this._updateDataTableParser()
			this._initDataTable(this._currentActiveMenuBarKey, 1, true)
		},

		_initDataTable: async function(key: number, page = 1, forceRefresh = false) {
			switch (key) {
				case Module1.M13.Defaults.MenuBarKeyOptions.INTERNAL_STORAGE: {
					const _idUser = Store.getters.getStoredUser?._idUser
					const response = await Store.dispatch('fetchZonesForPage', { forceRefresh, _idUser, itemsPerPage: this._dataTable.itemsPerPage, page })
					const { fields, items, actions } = this.states.dataTableParser(response.data)
					this._dataTable.updateElementsAndPagination(response.totalPages, fields, items, actions)
					break
				}
				case Module1.M13.Defaults.MenuBarKeyOptions.INTERNAL_ASSIGNMENT: {
					const { _idStorage } = this._horizontalStorageSelector.states.selected
					const response = await Store.dispatch('fetchAssignedEquipmentsForPage', { forceRefresh, _idStorage, itemsPerPage: this._dataTable.itemsPerPage, page })
					const { fields, items, actions } = this.states.dataTableParser(response.data)
					this._dataTable.updateElementsAndPagination(response.totalPages, fields, items, actions)
					break
				}
			}
		},

		_initPermissions: function() {
			this._dataTable.setPermission('ACTION_EDIT', true)
			this._dataTable.setPermission('ACTION_DELETE', true)
			this._dataTable.setPermission('NEW_BUTTON', true)
		},

		_onResponsiveBreakpoint: function(breakpoint: number) {
			// Controlar la Visibilidad de los elementos según el Breakpoint.
			const stacked = breakpoint <= Breakpoints.XLarge
			this._dataTable.setStates<DataTableRef['states']>({ stacked })
			this._basicHeader.setStates<BasicHeaderRef['states']>({ isMobile: breakpoint <= Breakpoints.Medium })
			this._menuBar.setItems(Module1.M13.Collections.MenuBarOptions(breakpoint <= Breakpoints.Large))
			this._menuBar.setStates<MenuBarRef['states']>({ isMobile: breakpoint <= Breakpoints.Large })
			this._updateDataTableParser()
			this._initDataTable(this._currentActiveMenuBarKey, 1, false)
		},
		
		_toggleComponents: function(showDataTable: boolean, showInternalStorageForm: boolean, showHorizontalStorageSelector: boolean) {
			this.setStates<View13Ref['states']>({ showDataTable, showHorizontalStorageSelector, showInternalStorageForm })
		},

		_updateDataTableParser: function() {
			const { stacked } = this._dataTable.states
			const menuBarKey  = this._currentActiveMenuBarKey

			// El 'Parser' debe ser actualizado según cambie la Pestaña o la propiedad 'stacked'.
			switch (menuBarKey) {
				case Module1.M13.Defaults.MenuBarKeyOptions.INTERNAL_STORAGE: {
					this.setStates<View13Ref['states']>({ dataTableParser: stacked ? parseStackedZonesResponse : parseZonesResponse })
					break
				}
				case Module1.M13.Defaults.MenuBarKeyOptions.INTERNAL_ASSIGNMENT: {
					this.setStates<View13Ref['states']>({ dataTableParser: stacked ? parseStackedEquipmentsResponse : parseEquipmentsResponse })
					break
				}
			}
		},

		_updateEquipmentZone: async function() {
			// Datos requeridos para la petición.
			const _idZone      = this.states.documentToUpdate?._idZone
			const _idEquipment = this.states.documentToUpdate?._idEquipment

			// Objeto con las Propiedades requeridas por la Petición.
			const body = {
				_idZone, _idEquipment
			}

			// Validación de los campos de la petición.
			const joiSchema = Module1.M13.JoiSchemas.UpdateEquipmentZone
			const result = joiSchema.validate(body)
			if (result.error) return JoiManager.showToastOnError(this.showToast, 'Error al Asignar la Ubicación Interna', result.error)

			// Realizar la Petición al servidor.
			this._internalStorageForm.setStates<InternalStorageFormRef['states']>({ isFetching: true })
			const response = await this.doFetch({ action: Server.Fetching.Method.PATCH, path: Server.Routes.Zones.UpdateZoneEquipment, body })

			// Si se obtiene una respuesta satisfactoria, continuar con el proceso.
			if (response.status === Server.Response.StatusCodes.SUCCESS) {
				// Actualizar los Registros de la Tabla.
				this._updateDataTableParser()
				await this._initDataTable(this._currentActiveMenuBarKey, 1, true)

				// Reiniciar la Data de los Componentes.
				this._internalStorageForm.clear()
				this._toggleComponents(true, false, true)
				
				// Mostrar Mensaje.
				this.showToast('Asignación Actualizada', 'Se actualizó la Ubicación Interna seleccionada!', 'success')
			}
		},

		_updateInternalStorageForm: function() {
			// Referencias a Componentes y Datos.
			const { documentToUpdate } = this.states

			// Datos que provienen de la fila seleccionada.
			const { zoneCode } = documentToUpdate
			const { zoneName } = documentToUpdate

			// Completar propiedades según la Variante activa.
			switch (this.states.variantInternalStorageForm) {
				case 'InternalStorage': {
					// Datos que provienen de la fila seleccionada.
					const { _idStorage } = documentToUpdate
					const { equipments } = documentToUpdate

					// Datos que se deben autocompletar para actualizar la Ubicación Interna.
					this._internalStorageForm.setStates<InternalStorageFormRef['states']>({
						action: Component.Actions.UPDATE,
						assignedEquipments: [...equipments],
						storageSelected: _idStorage,
						zoneCode,
						zoneName
					})

					// Autocompletar los Equipos que han sido Asignados.
					this._internalStorageForm.updateAssignedItems(null, null)
					break
				}
				case 'AssignedEquipment': {
					// Datos que provienen de la fila seleccionada.
					const { equipmentCode }          = documentToUpdate
					const { equipmentGroupArticle }  = documentToUpdate
					const { equipmentName }          = documentToUpdate
					const { equipmentTypeEquipment } = documentToUpdate

					// Obtener el '_idStorage' de la Ubicación seleccionada.
					const { _idStorage } = this._horizontalStorageSelector.states.selected

					// Datos que se deben autocompletar para actualizar la Ubicación Interna asignada.
					this._internalStorageForm.setStates<InternalStorageFormRef['states']>({
						action: Component.Actions.UPDATE,
						equipmentCode,
						equipmentGroup: equipmentGroupArticle,
						equipmentName,
						equipmentType: equipmentTypeEquipment,
						storageSelected: _idStorage,
						zoneCode,
						zoneName
					})
					break
				}
			}
		},

		_upsertInternalStorage: async function() {
			// Referencias a Componentes y Datos.
			const { action } = this._internalStorageForm.states
			const isInserting = action === Component.Actions.INSERT

			// Datos requeridos para la petición.
			const _idZone    = this.states.documentToUpdate?._idZone
			const _idCreator = Store.getters.getStoredUser?._idUser
			const _idStorage = this._internalStorageForm.states.storageSelected
			const code       = this._internalStorageForm.states.zoneCode
			const name       = this._internalStorageForm.states.zoneName
			const equipments = this._internalStorageForm.states.assignedEquipments.map((x) => x._idEquipment)

			// Objeto con las Propiedades requeridas por la Petición.
			const body = isInserting ? {
				_idCreator, _idStorage, code, name, equipments
			} : {
				_idZone, name, equipments, isValid: true
			}

			// Validación de los campos de la petición.
			const joiSchema = isInserting ? Module1.M13.JoiSchemas.AddInternalStorage : Module1.M13.JoiSchemas.UpdateInternalStorage
			const result = joiSchema.validate(body)
			if (result.error) return JoiManager.showToastOnError(this.showToast, `Error al ${ isInserting ? 'Crear una' : 'Actualizar la' } Ubicación Interna`, result.error)

			// Realizar la Petición al servidor.
			this._internalStorageForm.setStates<InternalStorageFormRef['states']>({ isFetching: true })
			const fetchAction = isInserting ? Server.Fetching.Method.POST : Server.Fetching.Method.PATCH
			const fetchPath = isInserting ? Server.Routes.Zones.AddZones : Server.Routes.Zones.UpdateZone
			const response = await this.doFetch({ action: fetchAction, path: fetchPath, body })

			// Si se obtiene una respuesta satisfactoria, continuar con el proceso.
			if (response.status === Server.Response.StatusCodes.SUCCESS) {
				// Actualizar los Registros de la Tabla.
				this._updateDataTableParser()
				await this._initDataTable(this._currentActiveMenuBarKey, 1, true)

				// Reiniciar la Data de los Componentes.
				this._internalStorageForm.clear()
				this._toggleComponents(true, false, false)
				
				// Mostrar Mensaje
				const mAction = isInserting ? 'Creada' : 'Actualizada'
				this.showToast(`Ubicación Interna ${ mAction }`, `Ubicación Interna ${ mAction.toLowerCase() } correctamente!`, 'success')
			}
		},

		onDTButtonClick: function(key: string, { item }: any) {
			if (key === 'edit') {
				this.setStates<View13Ref['states']>({ action: Component.Actions.UPDATE, documentToUpdate: item })
				this._toggleComponents(false, true, false)
				this._updateInternalStorageForm()
			}
			else if (key === 'delete') {
				this.setStates<View13Ref['states']>({ documentToUpdate: item, showConfirmDialog: true })
			}
		},

		onDTNewButtonClick: function() {
			this.setStates<View13Ref['states']>({ action: Component.Actions.INSERT })
			this._toggleComponents(false, true, false)
		},

		onDTPaginationChanged: async function(page: number) {
			this._updateDataTableParser()
			this._initDataTable(this._currentActiveMenuBarKey, page, false)
		},

		onDTRefreshButtonClick: function() {
			this._updateDataTableParser()
			this._initDataTable(this._currentActiveMenuBarKey, 1, true)
		},

		onDTSearchButtonClicked: function(searching: any) {
			const { searchKey, searchValue } = searching

			// Realizar Petición de Busqueda (y Realizar Formateo con Parser).
			switch (this._currentActiveMenuBarKey) {
				case Module1.M13.Defaults.MenuBarKeyOptions.INTERNAL_STORAGE: {
					// Obtener el '_idUser' del Usuario Conectado.
					const { _idUser } = Store.getters.getStoredUser

					this._dataTable.doInputSearch(Server.Routes.Zones.GetZonesByUserFilter,
						this.states.dataTableParser,
						{ _idUser, searchKey, searchValue }
					)
					break
				}
				case Module1.M13.Defaults.MenuBarKeyOptions.INTERNAL_ASSIGNMENT: {
					// Obtener el '_idStorage' de la Ubicación seleccionada.
					const { _idStorage } = this._horizontalStorageSelector.states.selected

					this._dataTable.doInputSearch(Server.Routes.Zones.GetEquipmentsByStorageFilter,
						this.states.dataTableParser,
						{ _idStorage, searchKey, searchValue }
					)
					break
				}
			}
		},

		onHSSItemClick: function(item: any) {
			this._dataTable.clearPagination()
			this._initComponents()
		},

		onISFButtonClick: function(action: string) {
			if (action === 'submit') {
				switch (this._currentActiveMenuBarKey) {
					case Module1.M13.Defaults.MenuBarKeyOptions.INTERNAL_STORAGE: {
						this._upsertInternalStorage()
						break
					}
					case Module1.M13.Defaults.MenuBarKeyOptions.INTERNAL_ASSIGNMENT: {
						this._updateEquipmentZone()
						break
					}
				}
			}
			else if (action === 'cancel') {
				this._toggleComponents(true, false, this._currentActiveMenuBarKey === Module1.M13.Defaults.MenuBarKeyOptions.INTERNAL_ASSIGNMENT)
				this._internalStorageForm.clear()
			}
		},

		onISFSelectClick: function(item: any) {
			// Incluir el '_idZone' para efectuar la actualización.
			this.states.documentToUpdate._idZone = item._idZone

			// Actualizar los 'inputs' para que se vea reflejado el cambio.
			this._internalStorageForm.setStates<InternalStorageFormRef['states']>({
				zoneCode: item.zoneCode,
				zoneName: item.zoneName
			})
		},

		onMBItemClick: function(key: number) {
			// Prevenir cualquier acción si existe un proceso de petición.
			const { isFetching } = this._internalStorageForm.states
			if (isFetching) return

			// Actualizar la propiedad 'variant' drl componente 'InternalStorageForm'.
			switch (key) {
				case Module1.M13.Defaults.MenuBarKeyOptions.INTERNAL_STORAGE: {
					this.setStates<View13Ref['states']>({ variantInternalStorageForm: 'InternalStorage' })
					break
				} 
				case Module1.M13.Defaults.MenuBarKeyOptions.INTERNAL_ASSIGNMENT: {
					this.setStates<View13Ref['states']>({ variantInternalStorageForm: 'AssignedEquipment' })
					break
				} 
			}

			// Vaciar la Tabla antes de ejecutar la petición correspondiente.
			this._dataTable.clearAll()

			// Forzar la visibilidad del componente 'DataTable'.
			this._toggleComponents(true, false, key === Module1.M13.Defaults.MenuBarKeyOptions.INTERNAL_ASSIGNMENT)

			// Realizar un Refresh de los Registros.
			this._updateDataTableParser()
			this._initDataTable(key, 1, true)
		},

		onServerCaughtFetchException: function(path: string, resolution: Server.Fetching.Resolutions, status: number, error: any) {
			// Controlar las excepciones de las diferentes peticiones realizadas en la vista.
			if (path === Server.Routes.Zones.AddZones) {
				switch (status) {
					case Server.Response.StatusCodes.CONFLICT: {
						const errorInfo = ErrorHandler.parse(error, status)
						this.showToast('Error al Crear la Ubicación Interna', `El código '${ errorInfo.value }' no es un código válido o es posible que ya haya sido creado. Intente otro código.`, 'danger')
						this._internalStorageForm.setStates<InternalStorageFormRef['states']>({ isFetching: false })
						break
					}
				}
			}
			else if (path === Server.Routes.Zones.UpdateZone) {
				this.setStates<View13Ref['states']>({ isFetching: false })
				this._internalStorageForm.setStates<InternalStorageFormRef['states']>({ isFetching: false })
			}
		},

		onSPItemClick: function(item: any) {
			this.$router.push({ name: item.action })
		}
	}
})

// Exports
export default View13
export type View13Ref = InstanceType<typeof View13>