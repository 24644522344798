// ./
import { parseAssignedEquipmentsResponse, parseStackedAssignedEquipmentsResponse } from './response.parser'
import { parseInternalStoragesResponse, parseStackedInternalStoragesResponse }     from './response.parser'
import { parseStorageEquipmentsResponse, parseStackedStorageEquipmentsResponse } from './response.parser'

// Classes
import { DevelopmentTools } from '@/Classes/Static/DevelopmentTools'
import { ValueTools }       from '@/Classes/Static/ValueTools'

// Components (.vue)
import SubmitButton from '@/Components/Extras/SubmitButton/template.vue'
import DataTable    from '@/Components/Global/DataTable/template.vue'
import PopupTable   from '@/Components/Global/PopupTable/template.vue'

// Components (Refs)
import { DataTableRef }  from '@/Components/Global/DataTable/component'
import { PopupTableRef } from '@/Components/Global/PopupTable/component'

// Constants
import { Component } from '@/Constants/Global/Component'
import { Server }    from '@/Constants/Global/Server'
import { Breakpoints } from '@/Constants/Global/Breakpoints'

// Dependencies
import VueMixins from 'vue-typed-mixins'

// Mixins
import MixinBase       from '@/Mixins/MixinBase'
import MixinComponent  from '@/Mixins/MixinComponent'
import MixinFetch      from '@/Mixins/MixinFetch'
import MixinResponsive from '@/Mixins/MixinResponsive'

// Store
import Store from '@/Store/Global/Default'

// Component Extend
const InternalStorageForm = VueMixins(MixinBase, MixinComponent, MixinFetch, MixinResponsive).extend({
	name: 'InternalStorageForm',

	components: {
		DataTable,
		PopupTable,
		SubmitButton
	},

	props: {
		show: Boolean,
		variant: String
	},

	data: function() {
		return {
			states: {
				action: Component.Actions.INSERT,
				assignedEquipments: [] as Array<any>,
				dataTableParser: null,
				equipmentCode: '',
				equipmentGroup: '',
				equipmentName: '',
				equipmentType: '',
				isFetching: false,
				popupDataTableParser: null,
				showPopupTable: false,
				storageSelected: null,
				userStorages: [] as Array<any>,
				zoneCode: '',
				zoneName: ''
			}
		}
	},

	mounted: function() {
		this._fetchUserStorages()
		this._initPermissions()
		this._dataTable.setStates<DataTableRef['states']>({ preventDefaultStacked: true, showExportButtons: false, showRefreshButton: false })
		this._popupTable._dataTable.setStates<DataTableRef['states']>({ isLocalSearch: false, preventDefaultStacked: true, showExportButtons: false })
		this._popupTable._dataTable.setSelectable(true)
	},

	computed: {
		_dataTable: function(): DataTableRef {
			return this.$refs.dataTable as DataTableRef
		},

		_getStorages: function(): Array<any> {
			return this.states.userStorages.map((x) => ({
				value: x._idStorage, text: x.storageName
			}))
		},

		_getStorageLabelText: function(): string {
			return this.variant === 'InternalStorage' ? 'Seleccionar Planta' : 'Planta Principal'
		},

		_isEquipmentsListEmpty: function(): boolean {
			return this.states.assignedEquipments.length === 0
		},

		_isInserting: function(): boolean {
			return this.states.action === Component.Actions.INSERT
		},

		_popupTable: function(): PopupTableRef {
			return this.$refs.popupTable as PopupTableRef
		}
	},

	methods: {
		clear: function() {
			// Limpiar los campos afectados por el Usuario.
			this.setStates<InternalStorageFormRef['states']>({
				action: Component.Actions.INSERT,
				assignedEquipments: [],
				isFetching: false,
				zoneCode: '',
				zoneName: ''
			})

			// Eliminar los Datos del componente 'DataTable'.
			this._dataTable.clearAll()
		},

		_fetchInternalStorages: async function(page = 1) {
			// Cambiar Estado de Carga.
			this._popupTable._dataTable.setStates<DataTableRef['states']>({ isFetching: true })

			// Actualizar el Parser según las Tablas visibles.
			this._updateTableParsers(this._popupTable._dataTable._isStacked)

			// Realizar la Petición al servidor.
			const { storageSelected } = this.states
			const params = { _idStorage: storageSelected, itemsPerPage: this._dataTable.itemsPerPage, page }
			const response = await this.doFetch({ action: Server.Fetching.Method.GET, path: Server.Routes.Zones.GetZonesByStorage, params })

			// Si se obtiene una respuesta satisfactoria, continuar con el proceso.
			if (response.status === Server.Response.StatusCodes.SUCCESS) {
				const _response = response.data.body[0]
				const { fields, items, actions } = this.states.popupDataTableParser(_response.data)
				this._popupTable._dataTable.updateElementsAndPagination(_response.totalPages, fields, items, actions)
			}
		},

		_fetchStorageEquipments: async function(page = 1) {
			// Cambiar Estado de Carga.
			this._popupTable._dataTable.setStates<DataTableRef['states']>({ isFetching: true })

			// Actualizar el Parser según las Tablas visibles.
			this._updateTableParsers(this._popupTable._dataTable._isStacked)

			// Realizar la Petición al servidor.
			const { storageSelected } = this.states
			const params = { _idStorage: storageSelected, itemsPerPage: this._dataTable.itemsPerPage, page }
			const response = await this.doFetch({ action: Server.Fetching.Method.GET, path: Server.Routes.Zones.GetEquipmentsForStorage, params })

			// Si se obtiene una respuesta satisfactoria, continuar con el proceso.
			if (response.status === Server.Response.StatusCodes.SUCCESS) {
				const _response = response.data.body[0]
				const { fields, items, actions } = this.states.popupDataTableParser(_response.data)
				this._popupTable._dataTable.updateElementsAndPagination(_response.totalPages, fields, items, actions)
			}
		},

		_fetchUserStorages: async function() {			
			// Realizar la Petición al servidor.
			const { _idUser } = Store.getters.getStoredUser
			const response = await this.doFetch({ action: Server.Fetching.Method.GET, path: Server.Routes.Zones.GetStoragesByUser, params: { _idUser } })

			// Si se obtiene una respuesta satisfactoria, continuar con el proceso.
			if (response.status === Server.Response.StatusCodes.SUCCESS) {
				const userStorages = response.data.body
				this.setStates<InternalStorageFormRef['states']>({ userStorages })
				if (userStorages?.length > 0) this.setStates<InternalStorageFormRef['states']>({ storageSelected: userStorages[0]._idStorage })
			}
		},

		_getDisableIfStorageNull: function() {
			return ValueTools.isNullOrUndefined(this.states.storageSelected)
		},
		
		_getEmptyDateClass: function(propName: string) {
			return (<any> this.states)[propName] === '' ? 'empty' : ''
		},
		
		_getSubmitButtonText: function() {
			return `${ this.states.action === Component.Actions.INSERT ? 'Crear' : 'Actualizar' } Ubicación Interna`
		},

		_initPermissions: function() {
			this._dataTable.setPermission('ACTION_DELETE', true)
			this._dataTable.setPermission('ACTION_EDIT', true)
			this._dataTable.setPermission('NEW_BUTTON', true)
		},

		_onResponsiveBreakpoint: function(breakpoint: number) {
			// Cambios que afectan el aspecto visual de los Componentes (según Breakpoint).
			const stacked = breakpoint <= Breakpoints.XLarge
			this._dataTable.setStates<DataTableRef['states']>({ stacked })
			this._popupTable._dataTable.setStates<DataTableRef['states']>({ stacked })

			// Actualizar el Parser según las Tablas visibles.
			this._updateTableParsers(stacked)

			// Actualizar los Registros de las Tablas.
			switch (this.variant) {
				case 'InternalStorage': {
					if (this.states.showPopupTable) this._fetchStorageEquipments()
					this.updateAssignedItems(null, null)
					break
				}
				case 'AssignedEquipment': {
					this._fetchInternalStorages()
					break
				}
			}
		},

		_updateTableParsers: function(stacked: boolean) {
			// Actualizar el Parser según las Tablas visibles.
			switch (this.variant) {
				case 'InternalStorage': {
					this.setStates<InternalStorageFormRef['states']>({ popupDataTableParser: stacked ? parseStackedStorageEquipmentsResponse : parseStorageEquipmentsResponse })
					this.setStates<InternalStorageFormRef['states']>({ dataTableParser: stacked ? parseStackedAssignedEquipmentsResponse : parseAssignedEquipmentsResponse })
					break
				}
				case 'AssignedEquipment': {
					this.setStates<InternalStorageFormRef['states']>({ popupDataTableParser: stacked ? parseStackedInternalStoragesResponse : parseInternalStoragesResponse })
					break
				}
			}
		},

		updateAssignedItems: function(action: string, item: any) {
			if (action && item) {
				// Agregar o Eliminar Equipo.
				switch (action) {
					case 'add': {
						this.states.assignedEquipments.push(item)
						break
					}
					case 'delete': {
						const index = this.states.assignedEquipments.findIndex((x) => x.equipmentCode === item.equipmentCode)
						if (index > -1) this.states.assignedEquipments.splice(index, 1)
						break
					}
				}
			}

			const { fields, items, actions } = this.states.dataTableParser(this.states.assignedEquipments)
			this._dataTable.setElements(fields, items, actions)
		},

		onDTButtonClick: function(key: string, { item }: any) {
			DevelopmentTools.printWarn('[InternalStorageForm]:onDTButtonClick() event triggered')
			this.updateAssignedItems(key, item)
		},

		onDTNewButtonClick: function() {
			// Impedir realizar alguna acción si 'isFetching' es 'true'.
			if (this.states.isFetching) return

			DevelopmentTools.printWarn('[InternalStorageForm]:onDTNewButtonClick() event triggered')
			this.setStates<InternalStorageFormRef['states']>({ showPopupTable: true })
			this._popupTable.setTitle('Selección de Equipo')
			this._fetchStorageEquipments()
		},
		
		onISFButtonClick: function(action: string) {
			DevelopmentTools.printWarn('[InternalStorageForm]:onISFButtonClick() event triggered')
			this.$emit('onISFButtonClick', action)
		},

		onISFClearSelection: function() {
			DevelopmentTools.printWarn('[InternalStorageForm]:onISFClearSelection() event triggered')
			this.setStates<InternalStorageFormRef['states']>({ assignedEquipments: [] })
			this._dataTable.clearAll()
		},

		onISFRefreshButtonClick: function() {
			DevelopmentTools.printWarn('[InternalStorageForm]:onISFRefreshButtonClick event triggered')

			// Actualizar los Registros de las Tablas.
			switch (this.variant) {
				case 'InternalStorage': {
					this._fetchStorageEquipments()
					break
				}
				case 'AssignedEquipment': {
					this._fetchInternalStorages()
					break
				}
			}
		},

		onISFSelectClick: function() {
			DevelopmentTools.printWarn('[InternalStorageForm]:onISFSelectClick event triggered')
			this.setStates<InternalStorageFormRef['states']>({ showPopupTable: true })
			this._popupTable.setTitle('Selección de Zonas')
			this._fetchInternalStorages()
		},

		onPTClose: function() {
			DevelopmentTools.printWarn('[InternalStorageForm]:onPTClose event triggered')
			this.setStates<InternalStorageFormRef['states']>({ showPopupTable: false })
		},

		onPTPaginationChanged: function(page: number) {
			DevelopmentTools.printWarn('[InternalStorageForm]:onPTPaginationChanged event triggered')
			this._fetchStorageEquipments(page)
		},

		onPTSearchButtonClicked: function(searching: any) {
			const { searchKey, searchValue } = searching
			const _idStorage = this.states.storageSelected

			// Realizar Petición de Busqueda (y Realizar Formateo con Parser).
			switch (this.variant) {
				case 'InternalStorage': {
					this._popupTable._dataTable.doInputSearch(Server.Routes.Zones.GetEquipmentsByStorageFilter,
						parseStorageEquipmentsResponse,
						{ _idStorage, searchKey, searchValue }
					)
					break
				}
				case 'AssignedEquipment': {
					this._popupTable._dataTable.doInputSearch(Server.Routes.Zones.GetZonesByStorageFilter,
						parseInternalStoragesResponse,
						{ _idStorage, searchKey, searchValue }
					)
					break
				}
			}
		},

		onRowDoubleClick: function({ item }: any) {
			DevelopmentTools.printWarn('[InternalStorageForm]:onRowDoubleClick():Event triggered')
			this.setStates<InternalStorageFormRef['states']>({ showPopupTable: false })
			this._popupTable._dataTable.clearCurrentRow()
			
			// El resultado de la Selección cambia según la propiedad 'variant'.
			switch (this.variant) {
				case 'InternalStorage': {
					this.updateAssignedItems('add', item)
					break
				}
				case 'AssignedEquipment': {
					this.$emit('onISFSelectClick', item)
					break
				}
			}
		}
	}
})

// Exports
export default InternalStorageForm
export type InternalStorageFormRef = InstanceType<typeof InternalStorageForm>