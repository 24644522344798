// Classes
import FieldsOptions         from '@/Classes/Records/FieldsOptions'
import { ItemMissingFields } from '@/Classes/Records/ItemMissingFields'
import { BuilderManager }    from '@/Classes/Responses/BuilderManager'
import { PrimitiveTools }    from '@/Classes/Static/PrimitiveTools'

// Constants
import { ActionFields } from '@/Constants/Global/ActionFields'
import { AppValues }    from '@/Constants/Global/AppValues'

// Store
import Store from '@/Store/Global/Default'

// Function Export
export function parseEquipmentsResponse(data: Array<any>) {
	// Clase Constructora.
	const bm = new BuilderManager(data, ActionFields.Edit)
	const { fields, items, actions } = bm.getElements()

	// Eliminar los campos que no queremos mostrar en la tabla.
	bm.removeFields(fields, [
		'_idEquipment', 'storageName'
	])

	// Aplicar opciones a los campos.
	bm.parseFieldsOptions(fields, new FieldsOptions()
		.add('equipmentCode', 'Codigo').isSortable().setAlign(null, 'center').setSearchKey('code')
		.add('equipmentName', 'Descripción').isSortable().setSearchKey('name')
		.add('equipmentMark', 'Marca').isSortable().setAlign(null, 'center').setSearchKey('mark')
		.add('equipmentTypeEquipment', 'Tipo Equipo').isSortable().setAlign(null, 'center').setSearchKey('typeEquipment')
		.add('equipmentTypeArticle', 'Tipo Articulo').isSortable().setAlign(null, 'center').setSearchKey('typeArticle')
		.add('equipmentGroupEquipment', 'Grupo Equipo').isSortable().setAlign(null, 'center').setSearchKey('groupEquipment')
		.add('equipmentGroupArticle', 'Grupo Articulo').isSortable().setAlign(null, 'center').setSearchKey('groupArticle')
		.add('equipmentYear', 'Año Equipo').setAlign(null, 'center')
		.add('zoneCode', 'Código Ubicación').isSortable().setSearchKey('zone.code')
		.add('zoneName', 'Descripción Ubicación').isSortable().setSearchKey('zone.name')
	)

	// Aplicar un nuevo Orden a todas las columnas visibles.
	bm.rearrangeFields(fields, [
		'equipmentCode', 'equipmentName', 'equipmentMark', 'equipmentTypeEquipment', 'equipmentTypeArticle', 'equipmentGroupEquipment', 'equipmentGroupArticle',
		'equipmentYear', 'zoneCode', 'zoneName'
	])

	// Aplicar un valor a los campos e items si no estan presentes.
	bm.parseItemsMissingFields(items, new ItemMissingFields()
		.add('equipmentGroupEquipment')
		.add('equipmentMark')
		.add('zoneCode')
		.add('zoneName')
	)

	// Retornar Objeto con los Campos, Items y Acciones.
	return { fields, items, actions }
}

export function parseStackedEquipmentsResponse(data: Array<any>) {
	// Clase Constructora.
	const bm = new BuilderManager(data)
	const { fields, items, actions } = bm.getElements()

	// Eliminar los campos que no queremos mostrar en la tabla.
	bm.removeFields(fields, [
		'_idZone', '_idStorage', '_idCreator',  'equipments',
		'isValid', '_idEquipment', 'equipmentMark', 'equipmentGroupEquipment', 'equipmentTypeArticle', 'equipmentYear',
		'storageName', 'zoneCode', 'zoneName', 'equipmentTypeEquipment', 'equipmentGroupArticle'
	])

	// Aplicar opciones a los campos.
	bm.parseFieldsOptions(fields, new FieldsOptions()
		.add(`equipmentCode`, 'Código').isSortable().setAlign(null, 'center').setSearchKey('code').showDetailsButton()
		.add(`equipmentName`, 'Descripción').isSortable().setAlign(null, 'center').setSearchKey('name')
	)

	// Aplicar un nuevo Orden a todas las columnas visibles.
	bm.rearrangeFields(fields, [
		'equipmentCode', 'equipmentName'
	])

	//Definir los campos a mostrar en el Detalle.
	bm.parseItemsDetail(items, {
		['Tipo Activo']: ({ equipmentTypeEquipment }) => {
			return equipmentTypeEquipment ? equipmentTypeEquipment : AppValues.Strings.DEFAULT_EMPTY_STRING
		},
		['Grupo Activo']: ({ equipmentGroupArticle }) => {
			return equipmentGroupArticle ? equipmentGroupArticle : AppValues.Strings.DEFAULT_EMPTY_STRING
		},
		['Marca']: ({ equipmentMark }) => {
			return equipmentMark ? equipmentMark : AppValues.Strings.DEFAULT_EMPTY_STRING
		},
		['Año']: ({ equipmentYear }) => {
			return equipmentYear ? equipmentYear : AppValues.Strings.DEFAULT_EMPTY_STRING
		},
		['Planta']: ({ storageName }) => {
			return storageName ? storageName : AppValues.Strings.DEFAULT_EMPTY_STRING
		},
		['Código Ubicación']: ({ zoneCode }) => {
			return zoneCode ? zoneCode : AppValues.Strings.DEFAULT_EMPTY_STRING
		},
		['Descripción Ubicación']: ({ zoneName }) => {
			return zoneName ? zoneName : AppValues.Strings.DEFAULT_EMPTY_STRING
		},
		['Acciones']: ActionFields.Edit
	})

	// Retornar Objeto con los Campos, Items y Acciones.
	return { fields, items, actions }
}

// Function Export
export function parseZonesResponse(data: Array<any>) {
	// Clase Constructora.
	const bm = new BuilderManager(data, ActionFields.EditDelete)
	const { fields, items, actions } = bm.getElements()

	// Eliminar los campos que no queremos mostrar en la tabla.
	bm.removeFields(fields, [
		'_idZone', '_idStorage', '_idCreator',  'equipments',
		'zoneIsValid'
	])

	// Aplicar opciones a los campos.
	bm.parseFieldsOptions(fields, new FieldsOptions()
		.add('zoneCode', 'Codigo').isSortable().setAlign(null, 'center').setSearchKey('zone.code')
		.add('zoneName', 'Descripción').isSortable().setSearchKey('zone.name')
		.add('zoneDateStart', 'Fecha Creación').setAlign(null, 'center')
		.add('creatorName', 'Creado por').isSortable().setAlign(null, 'center').setSearchKey('creator.name')
		.add('storageCode', 'Codigo Planta ').isSortable().setAlign(null, 'center').setSearchKey('storage.code')
		.add('storageName', 'Descripcion Planta').isSortable().setAlign(null, 'center').setSearchKey('storage.name')
	)

	// Aplicar un nuevo Orden a todas las columnas visibles.
	bm.rearrangeFields(fields, [
		'zoneCode', 'zoneName','zoneDateStart', 'creatorName', 'storageCode', 'storageName'
	])

	// Aplicar un valor a los campos e items si no estan presentes.
	bm.parseItemsMissingFields(items, new ItemMissingFields()
		.add('zoneCode')
		.add('zoneName')
		.add('creatorName')
		.add('storageCode')
		.add('storageName')
	)

	// Retornar Objeto con los Campos, Items y Acciones.
	return { fields, items, actions }
}

export function parseStackedZonesResponse(data: Array<any>) {
	// Clase Constructora.
	const bm = new BuilderManager(data)
	const { fields, items, actions } = bm.getElements()

	// Eliminar los campos que no queremos mostrar en la tabla.
	bm.removeFields(fields, [
		'_idZone', '_idStorage', '_idCreator',  'equipments',
		'isValid', 'zoneDateStart', 'creatorName', 'storageCode', 'storageName', 'zoneIsValid'
	])

	// Aplicar opciones a los campos.
	bm.parseFieldsOptions(fields, new FieldsOptions()
		.add(`zoneCode`, 'Cód. Ubicación').isSortable().setAlign(null, 'center').setSearchKey('zone.code').showDetailsButton()
		.add(`zoneName`, 'Descripción').isSortable().setAlign(null, 'center').setSearchKey('zone.name')
	)

	// Aplicar un nuevo Orden a todas las columnas visibles.
	bm.rearrangeFields(fields, [
		'zoneCode', 'zoneName'
	])

	// Definir los campos a mostrar en el Detalle.
	bm.parseItemsDetail(items, {
		['Fecha Creación']: ({ zoneDateStart }) => {
			const date = zoneDateStart ? zoneDateStart : null
			const _date = PrimitiveTools.Dates.parseDateString(date)
			return _date ? `${ _date.date } ${ _date.time }` : AppValues.Strings.DEFAULT_EMPTY_STRING
		},
		['Creado por']: ({ creatorName }) => {
			return creatorName ? creatorName : AppValues.Strings.DEFAULT_EMPTY_STRING
		},
		['Cód. Planta']: ({ storageCode }) => {
			return storageCode ? storageCode : AppValues.Strings.DEFAULT_EMPTY_STRING
		},
		['Descripción Planta']: ({ storageName }) => {
			return storageName ? storageName : AppValues.Strings.DEFAULT_EMPTY_STRING
		},	
		['Acciones']: ActionFields.EditDelete
	})

	// Retornar Objeto con los Campos, Items y Acciones.
	return { fields, items, actions }
}